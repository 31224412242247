// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-examples-jsx": () => import("./../../../src/pages/examples.jsx" /* webpackChunkName: "component---src-pages-examples-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-patterns-jsx": () => import("./../../../src/pages/patterns.jsx" /* webpackChunkName: "component---src-pages-patterns-jsx" */),
  "component---src-pages-principles-jsx": () => import("./../../../src/pages/principles.jsx" /* webpackChunkName: "component---src-pages-principles-jsx" */),
  "component---src-pages-templates-blog-category-template-jsx": () => import("./../../../src/pages/templates/blog-category-template.jsx" /* webpackChunkName: "component---src-pages-templates-blog-category-template-jsx" */),
  "component---src-pages-templates-blog-post-template-jsx": () => import("./../../../src/pages/templates/blog-post-template.jsx" /* webpackChunkName: "component---src-pages-templates-blog-post-template-jsx" */),
  "component---src-pages-templates-example-template-jsx": () => import("./../../../src/pages/templates/example-template.jsx" /* webpackChunkName: "component---src-pages-templates-example-template-jsx" */),
  "component---src-pages-templates-pattern-template-jsx": () => import("./../../../src/pages/templates/pattern-template.jsx" /* webpackChunkName: "component---src-pages-templates-pattern-template-jsx" */),
  "component---src-pages-templates-principle-template-jsx": () => import("./../../../src/pages/templates/principle-template.jsx" /* webpackChunkName: "component---src-pages-templates-principle-template-jsx" */)
}

